// Sets defaults for variables that normally come from drupal_js_features.tmpl
var site = site || {};
site.features = site.features || {
  search_type : 'endeca',
  use_frontend_device_detection : true,
  analytics_tagged : true,
  has_giftcards : true,
  personalization_is_enabled : true,
  has_waitlist_notification : true,
  has_favorites : true,
  allows_anonymous_checkout : true,
  show_offer_entry_field : true,
  has_checkout_samples : true,
  has_samples_only_checkout : false,
  has_auto_replenishment : true,
  uses_cheetah_mail_for_registration_email : true,
  uses_cheetah_mail_for_email_signup_email : true,
  pre_check_optin : false,
  has_loyalty_program : true,
  has_pro_site : false,
  show_legal_acceptance_checkbox : false
};
var drupal_user_info = {};
if (typeof tms_page_data != 'undefined'){
  if (typeof tms_page_data.tms_page_info != 'undefined'){
    if (tms_page_data.tms_page_info.user_info == null ){
      tms_page_data.tms_page_info.user_info = drupal_user_info;
    }
  }
}